.index-Warranty {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  flex-direction: column;
  font-family: iran-sans-b;

  .warranty-banner {
    width: 100%;
  }
  .warrantycard {
    direction: ltr;
    background-color: #fff;
    width: 99%;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 10px;
    padding: 1em;
    margin: 0 auto;

    label {
      color: #1d4c61;
    }
    input {
      width: 60%;
      padding: 0.4em 0;
      border-radius: 5px;
      border: 1px solid #d6d6d6;
      font-family: iran-sans-r;
      margin: 0.5em 0.5em 0.5em 0.5em;
    }
    button {
      padding: 0.45em 2em;
      border-radius: 5px;
      background-color: #00aeb4;
      color: #fff;
      font-family: iran-sans-b;
      border: none;
      cursor: pointer;
      width: 38%;
    }
  }
  .support {
    width: 99%;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    background-color: #02a1a7;
    padding: 1em;
    border-radius: 10px;

    .payment-icon {
      position: relative;
      top: 4px;
    }
  }
}

@media (max-width: 670px) {
  .index-Warranty .warrantycard input {
    width: 100%;
  }
}
