.button {
    padding: 0.5em 1em;
    border-radius: 10px;
    background-color: #00AEB4;
    border: none;
    font-family: iran-sans-b;
    color: #fff;
    cursor: pointer;

    &.button-outline {
        background-color: #fff;
        border: 1px solid #7d7fae;
    }
}
