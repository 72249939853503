footer {
  width: 100%;
  height: auto;
  position: relative;
  font-family: iran-sans-b;
#emailInput{
  text-align: left;
  border: 1px solid  rgb(214, 214, 214);
}
  .top {
    width: 100%;
    height: 400px;
    background: url(../../assets/svg/wave.svg) no-repeat;
    background-size: cover;
    background-position: left;
    position: relative;
    overflow: hidden;

    &::after {
      content: "";
      width: 200px;
      height: 150px;
      background-color: #fff;
      position: absolute;
      left: 0px;
      top: 70px;
      transform: rotate(5deg);
    }
  }
  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 200px;
    left: 0;
    padding: 1em;
    background-color: #fff;

    .head {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1367px;
      margin: 0 auto;

      .right {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 1em;
        width: 50%;
        flex-wrap: wrap;

        section {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-start;
          font-family: iran-sans-b;
          color: #6a6ca0;

          .title {
            font-size: 16px;
            margin-bottom: 15px;
          }
          a {
            font-size: 14px;
            margin: 5px 0;
            color: #6a6ca0;
          }
        }
      }
      .left {
        margin-right: 20px;
        
        .title {
          font-size: 16px;
          margin-bottom: 15px;
          color: #6a6ca0;
          text-align: left;
        }
        .tip {
          font-size: 14px;
          margin: 5px 0;
          color: #6a6ca0;
          text-align: left;
        }
        form {
          display: flex;

          input {
            padding: 1em;
            background-color: #f8fdff;
            border: 1px solid #f5f5f5;
            border-radius: 10px;
            font-family: iran-sans;
            flex: 1;
            margin-right: 10px;
          }
        }
      }
    }
    .banner {
      background-color: #fff;
      max-width: 1367px;
      margin: 0.7em auto;
      padding-top: 1em;
      width: 100%;
      padding: 0.5em;
      background: linear-gradient(to left, #f2524d, #efb20c);
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .right {
        display: flex;
        align-items: center;
        .logo {
          width: 60px;
          height: 60px;
          background: url(../../assets/svg/logo.svg);
          background-size: cover;
          background-position: center;
          border-radius: 10px;
        }
        .title {
          font-family: iran-sans-b;
          color: #fff;
          margin-right: 15px;
          margin-left: 15px;
        }
      }
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        img {
          position: relative;
          top: 5px;
        }

        .link {
          margin: 10px 10px;

          &.google-play {
            background: url(../../assets/images/googlePlay.png) no-repeat;
            width: 140px;
            height: 40px;
          }
          &.bazzar {
            background: url(../../assets/images/bazzar.png) no-repeat;
            width: 140px;
            height: 40px;
          }
          &.sib-app {
            background: url(../../assets/images/sibApp.png) no-repeat;
            width: 140px;
            height: 40px;
          }
        }
      }
    }
    .info {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1367px;
      margin: 0 auto;
      padding-top: 2em;

      .right {
        width: 100%;
        text-align: left;

        .title {
          color: #6a6ca0;
          font-size: 18px;
        }
        .description {
          color: #6a6ca0;
          font-size: 15px;
        }
      }
      .left {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link {
          margin: 0 10px;
          padding: 0.5em;
          border: 1px solid #d5d5d5;
          display: flex;
          justify-content: center;
          align-items: center;

          &.ettehadieh {
            background: url(../../assets/images/ettehadieh.png) no-repeat;
            background-position: center;
            width: 120px;
            height: 115px;
          }
          &.enamad {
            background: url(../../assets/images/emanad.png) no-repeat;
            background-position: center;
            width: 120px;
            height: 115px;
          }
        }
      }
    }
    .copy-right {
      background-color: #fff;
      width: 100%;
      position: relative;
      max-width: 1367px;
      margin: 0 auto;

      div {
        background-color: #fff;
        border-top: 1px solid #d4d4d4;
        padding: 10px 0;
        width: 100%;
        text-align: center;
        position: absolute;
        top: 40px;
        color: #6a6ca0;
        font-size: 15px;

        a {
          font-weight: 800;
          color: #ff5100;
        }
      }
      // position: absolute;
      // bottom: 0;
    }
  }
}
