.fiter-catalog {
  display: none;
}
.fiter-price {
  display: none;
}
.fiter-cat {
  display: none;
}

.menu-item-active-sort {
  background-color: #f6931e !important;
  color: #fff !important;
  border-radius: 20px;
}

.product-cover {
  position: relative;
  margin: auto;
  display: flex;
  justify-content: center;
  top: 50px;
  font-size: 35px;
  position: relative;
  z-index: 10;
  color: #6a6ca0;
}

.search-mobile {
  display: none;
}

.index-filter {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;

  .mobile-filters {
    display: none;
  }

  .aside {
    width: 22%;
  }

  .menu {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 5px;
    padding: 0.5em 0.7em;
    margin: 1em 0;

    hr {
      margin-bottom: 0.5em;
      opacity: 0.5;
    }

    .menu-title {
      font-family: iran-sans-b;
      color: #6a6ca0;
      font-size: 15px;
      padding: 0 0 0.5em 0;
      text-align: left;
    }

    .menu-item-active {
      color: #f6931e !important;
    }

    .menu-item {
      display: flex;
      align-items: center;
      font-family: iran-sans-r;
      padding: 0.5em 0 0.1em;
      color: #6a6ca0;
      position: relative;
      justify-content: end;
      cursor: pointer;

      svg {
        margin: 0 0 0 0.5em;
      }

      select {
        font-family: iran-sans-r;
        margin: 0.5em 0.5em 0.5em 0;
        width: 80%;
        padding: 0.2em 0.5em;
        border: 0;
        box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
        border-radius: 5px;
      }

      .price-title {
        color: #8b8b8b;
      }
      .first::after {
        content: "....";
        position: absolute;
        transform: rotate(90deg);
        top: 40px;
        left: 0px;
      }
    }
  }

  .products {
    width: 80%;
    margin-left: 1em;
  }

  .filter-section {
    background-color: #fff;
    margin: 1em 0;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 5px;
    position: relative;
    text-align: left;
    .title {
      font-family: iran-sans-b;
      color: #6a6ca0;
      padding: 0.5em 1em;
      font-size: 15px;
    }

    .first {
      color: #8b8b8b !important;
    }

    .filter {
      display: flex;
      justify-content: end;
      padding: 0.7em;
    }

    .item {
      font-family: iran-sans-b;
      color: #6a6ca0;
      display: flex;
      font-size: 14px;
      padding: 0.5em 1em;
      cursor: pointer;
    }

    .filter-icon img {
      position: absolute;
      bottom: 1.2em;
      right: 20px;
    }
  }
  .prooducts-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
}

#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

#react-paginate li {
  display: inline-block;
}

.pagination {
  font-family: iran-sans-b;
  color: #6a6ca0;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 1em 0 2em 0;
  direction: rtl;

  li {
    list-style: none;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    margin: 0 0.5em;
    border-radius: 5px;

    a {
      padding: 0.3em 0.9em;
      cursor: pointer;
      display: flex;
    }
  }

  li:first-child {
    border-radius: 0 7px 7px 0;
    margin: 0 15px;
  }

  li:last-child {
    border-radius: 7px 0 0 7px;
    margin: 0 15px;
  }

  .active {
    background-color: #f6931e;
    a {
      color: #fff;
    }
  }
}

@media (max-width: 670px) {
  .index-filter {
    flex-direction: column;
  }
  .mobile-filters {
    display: flex !important;
    justify-content: space-around;

    button {
      padding: 0.5em 0.7em;
      font-family: iran-sans-r;
      border-radius: 5px;
      border: 0;
      cursor: pointer;
      background-color: #00aeb4;
      color: #fff;
    }
  }
  .index-filter .aside {
    display: none;
  }
  .index-filter .filter-section {
    display: none;
  }
  .index-filter .products {
    width: 100%;
    margin-right: 0;
  }
  .product-card {
    width: 100% !important;
  }
  .fiter-catalog {
    background-color: #fff;
    position: fixed;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-family: iran-sans-r;
    border-radius: 20px;
    width: 90%;
    padding: 1em;
    z-index: 1000;
    display: none;

    .title {
      display: flex;
      justify-content: center;
      font-family: iran-sans-b;
      color: #7779a9;
      margin: 0.5em 0;
    }
    .items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.5em;
      a {
        text-align: center;
        padding: 0.5em;
        color: #7779a9;
      }
    }
  }

  .fiter-price {
    background-color: #fff;
    position: fixed;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-family: iran-sans-r;
    border-radius: 20px;
    width: 90%;
    padding: 1em;
    z-index: 1000;
    display: none;

    .title {
      display: flex;
      justify-content: center;
      font-family: iran-sans-b;
      color: #7779a9;
      margin: 0.5em 0;
    }
    .items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.5em;
      a {
        text-align: center;
        padding: 0.5em;
        color: #7779a9;
      }
      label {
        display: flex;
        justify-content: center;
        margin: 0.5em;
        color: #7779a9;
      }
      select {
        padding: 0.5em 1em;
        font-family: iran-sans-r;
        border-radius: 10px;
        border: 1px solid rgb(143, 143, 143);
      }
      input {
        padding: 0.5em 1em;
        font-family: iran-sans-r;
        border-radius: 10px;
        border: 1px solid rgb(143, 143, 143);
      }
      button {
        padding: 0.7em;
        margin: 1.5em 0;
        font-family: iran-sans-b;
        border-radius: 7px;
        border: 0;
        background-color: #00aeb4;
        color: #fff;
        cursor: pointer;
      }
    }
  }

  .fiter-cat {
    background-color: #fff;
    position: fixed;
    top: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    font-family: iran-sans-r;
    border-radius: 20px;
    width: 90%;
    padding: 1em;
    z-index: 1000;
    display: none;

    .title {
      display: flex;
      justify-content: center;
      font-family: iran-sans-b;
      color: #7779a9;
      margin: 0.5em 0;
    }
    .items {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.5em;
      a {
        text-align: center;
        padding: 0.5em;
        color: #7779a9;
        display: flex;
      }
    }
  }
}
