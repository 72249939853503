.index-notFound {
  direction: ltr;
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;

  img {
    width: fit-content;
    margin: 0 auto;
  }
  p {
    text-align: center;
    font-size: 18px;
    color: #753517;
  }
  a {
    width: 300px;
    padding: 0.5em;
    font-family: iran-sans-b;
    margin: 0.7em auto;
    border: 0;
    border-radius: 5px;
    color: #753517;
    cursor: pointer;
    background-color: #fff8ed;
    text-align: center;
    font-size: 14px;
  }

  .instagram-content {
    width: 75%;
    margin: 1em auto;
    text-align: justify;
    font-size: 16px;
    font-family: iran-sans-r;
  }

  .instagramKitLink {
    position: relative;
    top: 162px;
    z-index: 1;
    background-color: #fff;
    font-size: 18px;
  }
  .instagramKit {
    position: relative;
    top: -50px;
  }
}

@media (max-width: 670px) {
  .index-notFound {
    img {
      width: 100%;
    }
  }
}