.index-basketForm {
  width: 100% !important;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;

  .buttonSpin {
    display: none;
  }
  .card {
    width: 100%;
    max-width: 1367px;
    margin: 1em auto;
    background-color: #fff;
    padding: 1em;
    display: flex;
    font-family: iran-sans-b;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 5px;
  }
  .title {
    text-align: center;
    color: #1d4c61;
    margin: 0.5em 0;
  }
  .row {
    display: flex;
    width: 100% !important;
  }
  .row-pay {
    width: 100%;
    background-color: #f8fdff;

    .title {
      font-size: 14px;
      text-align: start;
      padding: 0 0.5em;
    }
    .way-pay {
      font-size: 14px;
      margin: 0.8em 0 0.8em 0.8em;
      color: #1d4c61;

      input {
        margin: 0 0.5em;
      }
    }
  }
  .col {
    width: 50%;
    display: flex;
    flex-direction: column;
    margin: 0.7em 0.5em;
  }
  .col-rule {
    width: 50%;
    display: flex;
    align-items: center;
    margin: 0.7em 0.5em;

    input {
      margin: 0 0 0 0.5em;
    }
  }
  .col-100 {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0.7em 0.5em;
  }
  label {
    font-family: iran-sans-r;
    font-size: 14px;
    color: #1d4c61;
    margin: 0 0 0.5em 0;
  }
  input {
    padding: 0.7em 1em;
    border-radius: 5px;
    border: 1px solid rgb(214, 214, 214);
    font-family: iran-sans-r;
  }
  select {
    padding: 0.5em 1em;
    border-radius: 5px;
    border: 1px solid rgb(214, 214, 214);
    font-family: iran-sans-r;
    font-size: 13px;
  }
  textarea {
    padding: 0.5em 1em;
    border-radius: 5px;
    border: 1px solid rgb(214, 214, 214);
    font-family: iran-sans-r;
    font-size: 13px;
  }
  button {
    width: 40%;
    height: 40px;
    padding: 0 3em;
    font-family: iran-sans-b;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: #00aeb4;
    color: #fff;
    margin: 0 auto 0 0;
  }
  a {
    width: 40%;
    height: 40px;
    padding: 0.3em 3em;
    font-family: iran-sans-b;
    border: 0;
    border-radius: 5px;
    cursor: pointer;
    background-color: #00aeb4;
    color: #fff;
    text-align: center;
  }
}
