@font-face {
  font-family: iran-sans;
  src: url("../../assets/fonts/irsns-light.ttf") format("true type");
  src: url("../../assets/fonts/irsns-light.woff") format("woff");
}

@font-face {
  font-family: iran-sans-r;
  src: url("../../assets/fonts/irsns-regular.ttf") format("true type");
  src: url("../../assets/fonts/irsns-regular.woff") format("woff");
}

@font-face {
  font-family: iran-sans-b;
  src: url("../../assets/fonts/irsns-bold.ttf") format("true type");
  src: url("../../assets/fonts/irsns-bold.woff") format("woff");
}

.product-card:hover {
  border: 1px solid #cacaca;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 5px #ececec;
}

.product-card {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: iran-sans;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;

  .title {
    color: #1d4c61;
    width: 230px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: iran-sans-b;
  }
  .picture {
    width: 230px;
    height: 230px;
    background-repeat: no-repeat;
    background-size: cover 100%;
    background-position: center;
    background-size: 100%;
  }
  .off {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    font-family: iran-sans-b;

    .right {
      direction: ltr;
      display: flex;
      justify-content: flex-end;
      text-align: left;
      align-items: center;

      img {
        margin-bottom: 5px;
      }
    }
    .left {
      text-align: end;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      .percent {
        background-color: #efb20c;
        border-radius: 10px;
        padding: 3px 10px;
        color: #1D4C61;
      }
      .money {
        color: #959595;
        text-decoration: line-through;
        margin-left: 5px;
      }
    }
  }
  .off-money {
    color: #1D4C61;
    font-size: 17px;
    text-align: end;
    margin-top: 10px;
    font-family: iran-sans-b;
  }
}

@media (max-width: 670px) {
  .product-card .title {
    padding: 0 0 0 2.5em !important;
  }
}