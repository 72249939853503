.index-faildPay {
  width: 100%;
  max-width: 1367px;
  margin: 2em auto 0;
  background-color: #f8fdff;
  padding: 2em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);

  img {
    width: 150px;
    margin: 0 auto;
  }
  .title-content {
    margin: 0 auto;
    font-size: 25px;
    font-family: iran-sans-r;
    font-weight: 900;
    color: rgb(190, 27, 95);
  }
  .subbtitle-content {
    margin: 0 auto;
    font-size: 18px;
    font-family: iran-sans-r;
    font-weight: 900;
  }
  .details-content {
    margin: 1em auto 0;
    font-size: 14px;
  }
  .site-contect {
    margin: 0 auto 1em;
    font-size: 14px;
    color: #808080;
  }
  a {
    width: 300px;
    margin: auto;
    padding: 0.7em;
    background-color: #fff;
    font-family: iran-sans-b;
    border: 0;
    border-radius: 5px;
    background-color: rgb(190, 27, 95);
    color: #fff;
    cursor: pointer;
    text-align: center;
  }
}

.index-successPay {
  width: 100%;
  max-width: 1367px;
  margin: 2em auto 0;
  background-color: #f8fdff;
  padding: 2em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);

  img {
    width: 150px;
    margin: 0 auto;
  }
  .title-content {
    margin: 0 auto;
    font-size: 25px;
    font-family: iran-sans-r;
    font-weight: 900;
    color: rgb(32, 165, 49);
  }
  .subbtitle-content {
    margin: 0 auto;
    font-size: 18px;
    font-family: iran-sans-r;
    font-weight: 900;
  }
  .details-content {
    margin: 1em auto 0;
    font-size: 14px;
  }
  .site-contect {
    margin: 0 auto 1em;
    font-size: 14px;
    color: #808080;
  }
  a {
    width: 300px;
    margin: auto;
    padding: 0.7em;
    background-color: #fff;
    font-family: iran-sans-b;
    border: 0;
    border-radius: 5px;
    background-color: rgb(56, 187, 74);
    color: #fff;
    cursor: pointer;
    text-align: center;
  }
}