.index-profile {
  width: 100%;
  max-width: 1367px;
  margin: 0 auto;
  background-color: #f8fdff;
  padding: 1em;
  display: flex;
  font-family: iran-sans-b;

  .aside {
    width: 22%;
    display: flex;
  }
  .menu {
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
    border-radius: 10px;
    padding: 0.5em 0.7em;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    svg {
      font-size: 60px;
      opacity: 0.5;
    }
    .name {
      display: flex;
      margin-top: 0.5em;
    }
    h3 {
      font-size: 16px;
      color: #7e7676;
    }
    h4 {
      font-size: 14px;
      font-family: iran-sans;
      color: #7e7676;
    }
    div {
      margin: 0 0.5em;
    }
  }
  hr {
    margin: 0.7em 0;
  }
  a {
    color: #7e7676;
  }
  .item {
    margin: 1em 0;
    display: flex;
    align-items: center;

    svg {
      opacity: 1;
      font-size: 20px;
      margin: 0 0 0 0.5em;
    }
  }
  .information {
    width: 80%;
    margin: 1em 1em 0 0;

    .title {
      color: #7e7676;
    }
    .row {
      display: flex;
    }
    .card {
      background-color: #fff;
      padding: 0.5em;
      box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);
      border-radius: 10px;
      margin: 0.5em 0;
    }
    .col {
      width: 50%;
      display: flex;
      flex-direction: column;
      margin: 0.7em 0.5em;
    }
    .col-100 {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin: 0.7em 0.5em;

      a {
        font-size: 14px;
        text-align: center;
        text-decoration: underline;
        font-family: iran-sans-r;
        color: #125b7b;
      }
    }
    label {
      font-family: iran-sans-r;
      font-size: 14px;
      color: #1d4c61;
      margin: 0 0 0.5em 0;
    }
    input {
      padding: 0.4em 1em;
      border-radius: 5px;
      border: 1px solid rgb(214, 214, 214);
      font-family: iran-sans-r;
    }
  }

  .order {
    table {
      width: 100%;
      color: #1d4c61;
      font-size: 14px;

      th {
        font-size: 14px;
      }
      td {
        padding: 1em 0;
        img {
          width: 70px;
          margin: 0 0 0 0.5em;
          border: 1px solid rgb(224, 224, 224);
          border-radius: 5px;
        }
      }
      .name {
        display: flex;
        align-items: center;

        h2 {
          font-size: 14px;
        }
        span {
          font-size: 12px;
        }
      }
      .price {
        text-align: center;
      }
      .count {
        text-align: center;
      }
      .status {
        text-align: center;
        color: green;
      }
    }
  }

  .deleteIcon {
    text-align: center;
    font-size: 20px;
    color: #ff3e3e;
    cursor: pointer;
    position: static;
    z-index: 1;

    svg {
      position: static;
      z-index: 0;
    }

    img {
      width: 25px !important;
    }
  }

  #follow {
    display: none;
  }
  #order {
    display: none;
  }
  #address {
    display: none;
  }
  #information {
    display: none;
  }

  .add-address-button {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 1em 2em;
      border-radius: 10px;
      background-color: #00aeb4;
      color: #fff;
      font-family: iran-sans-b;
      border: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 670px) {
  .index-profile {
    flex-direction: column;
  }
  .index-profile .aside {
    width: 100%;
  }
  .index-profile .information {
    width: 100%;
    margin: 0;
  }
  .index-profile .information .col {
    width: 44%;
  }
}
