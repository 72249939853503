.index-basketForm{
  direction: ltr;
}
.buttonSpinAgent {
  display: none;
}
.btnparent{
  direction: rtl;
  display: block !important;
  justify-content: start !important;
  align-items: flex-start     ;
}

.is-invalid {
  transition: all 0.3s ease-in-out;
  border: 1px solid rgb(255, 121, 121) !important;
}

.allert {
  color: rgb(255, 73, 73);
}

:local(.example) {
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  padding: 20px;
  margin-bottom: 20px;
}

:local(.example) h1,
:local(.example) h2,
:local(.example) h3 {
  border-bottom: 1px dashed rgba(238, 238, 238, 0.44);
  margin: 0 0 20px;
  color: #216ba5;
  padding-bottom: 10px;
}

:local(.row):after {
  content: "";
  clear: both;
  display: block;
}

:local(.row) > div {
  width: 50%;
  padding: 13px 0;
  float: right;
}

:local(.row) > :local(.description) {
  padding-left: 30px;
}

.birthdayInput {
  width: 100%;
}

.Toastify__toast-body {
  font-family: iran-sans !important;
}

pre {
  background-color: #f5f8fb !important;
  padding: 20px 10px !important;
  margin: 0 0 20px -20px;
  border-radius: 0 3px 3px 0;
  border-top: 1px solid #d8e4ef;
  border-bottom: 1px solid #d8e4ef;
  border-right: 1px solid #d8e4ef;
}

.tether-element > div > div {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 3px 10px #dbdbdb;
  margin: auto;
  padding: 10px;
  text-align: center;
  width: 300px;
  font-family: iran-sans;
}
.tether-element > div > div * {
  box-sizing: border-box;
}
.tether-element > div > div > div > div:nth-child(3)::after,
.tether-element > div > div > div > div:nth-child(2)::after,
.tether-element .month-selector > div:nth-child(2)::after {
  clear: both;
  content: "";
  display: block;
}
.tether-element > div > div > div > div:nth-child(1) {
  font-weight: 700;
  height: 42px;
  margin-bottom: 10px;
}
.tether-element button {
  font-family: iran-sans;
}
.tether-element > div > div > div > div:nth-child(1) > button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: 1px solid #f7f7f7;
  border-radius: 3px;
  cursor: pointer;
  height: 32px;
  line-height: 30px;
  margin: 5px 0;
  text-align: center;
  width: 36px;
}
.tether-element > div > div > div > div:nth-child(1) > button:hover {
  background-color: #f2f2f2;
}
.tether-element > div > div > div > div:nth-child(1) > span {
  line-height: 35px;
}
.tether-element > div > div > div > div:nth-child(1) svg {
  width: 10px;
}
.tether-element > div > div > div > div:nth-child(1) > button:nth-child(2) {
  float: right;
}
.tether-element > div > div > div > div:nth-child(1) > button:nth-child(3) {
  float: left;
}
.tether-element > div > div > div > div:nth-child(1) > button:nth-child(1) {
  border: 1px solid #f7f7f7;
  display: inline-block;
  font-size: 1em;
  font-weight: 400;
  height: 32px;
  line-height: 32px;
  margin: 5px 0;
  text-align: center;
  width: 120px;
}
.tether-element > div > div > div > div:nth-child(3) > div {
  float: right;
  width: 14.2857%;
}
.tether-element > div > div > div > div:nth-child(3) > div button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  cursor: pointer;
  height: 36px;
  outline: medium none;
  width: 100%;
}
.tether-element > div > div > div > div:nth-child(3) > div button:hover {
  background-color: #eef;
}
.tether-element > div > div > div > div:nth-child(3) > div button[disabled] {
  background-color: #ebebeb;
  color: #aaa;
  cursor: not-allowed;
}
.tether-element
  > div
  > div
  > div
  > div:nth-child(3)
  > div:not(.undefined)
  button {
  opacity: 0.5;
}
.tether-element > div > div > div > div:nth-child(2) {
  border-bottom: 1px solid #eee;
  display: flex;
  margin-bottom: 5px;
  padding-bottom: 5px;
  width: 100%;
}
.tether-element > div > div > div > div:nth-child(2) > div {
  flex-grow: 1;
  justify-content: space-between;
}
.tether-element .month-selector > div:nth-child(2) {
  clear: both;
  width: 100%;
  display: inline-table;
}
.tether-element .month-selector > div:nth-child(2) button {
  background: #fff none repeat scroll 0 0;
  border: 1px solid #f9f9f9;
  cursor: pointer;
  float: right;
  font-size: 1em;
  height: 25%;
  outline: medium none;
  padding: 10px 0;
  width: 33.3333%;
}
.tether-element .month-selector > div:nth-child(2) > div.undefined > button {
  background-color: #337ab7;
  color: #fff;
}
.tether-element .month-selector > div:nth-child(2) button:hover {
  background: #eee none repeat scroll 0 0;
  cursor: pointer;
}

@media (max-width: 670px) {
  .col {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    margin: 0.7em 0.5em;
  }
  .index-basketForm .row {
    flex-direction: column;
  }
  .index-basketForm .col {
    width: 100%;
  }
  .index-basketForm .col-100 {
    width: 95%;
  }
  .index-basketForm .col-rule {
    width: 100% !important;
  }
  .index-basketForm button {
    width: 100% !important;
  }
}

:local(.jumbotron) {
  background-color: #216ba5;
  color: #dedede;
  text-align: center;
  padding: 20px;
}

:local(.jumbotron) a {
  color: #fff;
  text-decoration: none;
}

:local(.examples) {
  padding-top: 30px;
}
