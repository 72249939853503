.index-rule {
  direction: ltr;
  width: 100%;
  max-width: 1367px;
  margin: 2em auto 0;
  background-color: #f8fdff;
  padding: 2em;
  display: flex;
  font-family: iran-sans-b;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(194, 194, 194, 0.5);

  .title {
    font-family: iran-sans-b;
    margin: 0.5em;
  }
  p {
    font-family: iran-sans;
    line-height: 2.1em;
  }
  hr {
    opacity: 0.5;
    margin: 1em 0;
  }
}