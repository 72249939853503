* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
html {
  direction: rtl;
}

body {
  width: 100%;
  background-color: #F8FDFF;
}

a {
  text-decoration: none;
}
