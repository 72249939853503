.menuMobile {
  direction: ltr;
  background-color: #fff;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1000;
  width: 70%;
  border-radius: 0px 40px 40px 0px;
  font-family: iran-sans-b;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style: none;
    margin: 2em 0;

    li {
      padding: 0.5em 1em;
      position: relative;

      cursor: pointer;

      img {
        width: 10px;
        transform: rotate(90deg);
        transition: 0.4s all ease-in-out;
      }
    }
    a {
      font-size: 15px;
      color: #7779a9;
      display: flex;
      align-items: center;
    }
  }
  img {
    width: 80px;
    margin: 1em;
  }
  hr {
    background-color: #7779a9;
    opacity: 0.5;
  }

  .secound-list {
    display: none;
    margin: 0.2em;
  }
  .secound-item {
    background-color: rgb(243, 243, 243);
  }

  .third-list {
    display: none;
    margin: 0.2em;
  }
  .third-item {
    background-color: rgb(243, 243, 243);
  }

  .display {
    display: block;
  }
  .titleColor {
    color: #f2820f;
  }
  .rotate {
    transform: rotate(0deg);
    transition: 0.4s all ease-in-out;
  }
}
