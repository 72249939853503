header {
  width: 100%;
  background-color: #fff;
  font-family: iran-sans-b;
  padding: 1em 1em 0 1em;

  .hamburger-menu {
    display: none;
  }

  .top {
    width: 100%;
    max-width: 1367px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .right {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 50%;

      .input {
        position: relative;
        margin-right: 20px;
        width: 60%;
        margin-left: 20px;

        .search {
          width: 100%;
          padding: 1em;
          border-radius: 10px;
          box-shadow: 1px 0px 24px 8px rgba(0, 0, 0, 0.1);
          background-color: #f8fdff;
          border: none;
          font-family: iran-sans-r;
          text-align: left;
        }

        .search-icon {
          position: absolute;
          right: 15px;
          top: 15px;
          color: #a8a8a8;
          transform: scaleX(-1);
        }
      }
    }

    .left {
      direction: ltr;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .go-to-bascket {
        padding: 0.8em;
        background-color: #f6931e;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        position: relative;
        margin-left: 10px;

        .bascket-icon {
          color: #fff;
          font-size: 25px;
        }

        .badge {
          position: absolute;
          top: -5px;
          right: -7px;
          padding: 0 7px;
          border-radius: 50%;
          background: #fff;
          border: 1px solid #f6931e;
          color: #f6931e;
          font-size: 12px;
          left: auto;
          z-index: 0;
        }
      }

      .sign-in {
        padding: 1em 2em;
        border-radius: 10px;
        background-color: #00aeb4;
        color: #fff;
        font-family: iran-sans-b;
        border: none;
        cursor: pointer;
        height: 49px;
      }

      .buttonText {
        display: none;
      }
    }
  }
}

.bottom {
  width: 100%;
  font-family: iran-sans-b;
  position: sticky;
  top: 0;
  z-index: 100;
  padding: 1em 0;
  background-color: #fff;
  box-shadow: 5px 5px 5px rgba(194, 194, 194, 0.5);

  .header-bottom {
    max-width: 1366px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .right {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    direction: ltr;

    .menu-icon {
      margin-left: 20px;
      color: #676767;
      font-size: 24px;
    }

    ul {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      list-style: none;

      li {
        margin: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: #676767;

        .arrow-down {
          color: #676767;
        }

        a {
          color: #676767;
        }

        a:hover {
          color: #f6931e;
        }

        &.ha-drop-down {
          position: relative;

          .drop-down {
            display: none;
            width: 100%;
          }

          &:hover {
            .drop-down {
              box-shadow: 0px 50px 24px 8px rgba(0, 0, 0, 0.07);
              position: absolute;
              top: 22px;
              padding-top: 10px;
              left: 50%;
              transform: translateX(-50%);
              display: block;
              background-color: #fff;
              width: 200%;
              border-radius: 10px;
              z-index: 9999;

              ul {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
                padding: 10px;

                li {
                  margin: 5px;
                  align-self: flex-start;
                  cursor: pointer;
                  color: #676767;
                }

                li a:hover {
                  color: #f6931e;
                }
              }
            }
          }
        }
      }
    }
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;

    .social-link {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
      color: #707070;

      .social-icon {
        font-size: 20px;
      }

      .telegram-icon {
        font-size: 22px;
      }

      .twitter-icon {
        font-size: 26px;
      }

      .instagram-icon {
        font-size: 23px;
      }
    }
  }
}

@media (max-width: 670px) {
  .hamburger-menu {
    display: flex !important;

    svg {
      font-size: 30px;
      color: #555555;
      cursor: pointer;
    }
  }

  .logo {
    width: 80px;
  }
}

.point {
  margin-top: 30px;
  margin-left: 20px;
}

.language-change {
  padding: 0 7px 0 3px;
  align-items: center;
  display: flex;
  text-align: center;
  justify-content: space-between;
  background-color: #f7b32e;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.English {
  margin-top: -1px;
  padding: 5px;
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}

.english-icon {
  margin-top: 1px;
}